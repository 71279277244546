import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

import { AnimationFade } from 'libs/animation'

import Theme from 'styles/_theme'
import GlobalStyle from 'styles/_global'
import { Wrapper } from 'styles/_mixins'

import Seo from 'components/Seo/Seo'
import Layout from 'components/Layout/Layout'
import Pagination from 'components/News/Pagination'
import BlogRelated from 'components/News/NewsRelated'

const Container = styled.div`
  width: 100%;
  height: fit-content;
`

const Card = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
`

const CardImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 85rem;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: ${({ theme: { colors } }) => colors.opblack};
  }
`

const CardBody = styled.div`
  width: 100%;
  height: fit-content;
  padding: 5rem 0;
`

const CardTitle = styled.p`
  font-size: 2.5rem;
  font-family: ${({ theme: { fonts } }) => fonts.montserrat};
  font-weight: bold;
  margin-bottom: 1rem;
`

const CardDate = styled.p`
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
`

const CardDescription = styled.div`
  margin-bottom: 8rem;
  line-height: 2.5rem;
  font-size: 1.5rem;

  & p {
    margin-bottom: 2rem;
  }
`

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(
      fileAbsolutePath: { regex: "/news/" }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        id
        slug
        title
        date
        description
        images {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100, formats: [AUTO, WEBP])
            }
          }
          alt
        }
      }
    }
  }
`

const Post = ({ data, pageContext }: any) => {
  const { slug, title, date, description, images } = data.markdownRemark.frontmatter

  return (
    <Theme>
      <GlobalStyle />
      <Seo title={title} description={description} />
      <Layout backgrounds={false}>
        <Container>
          <Card>
            <AnimationFade rootMargin='0px 0px' custom={0} durarion='1'>
              <CardImage
                image={images.src.childImageSharp.gatsbyImageData}
                alt={images.alt}
                objectFit='cover'
              />
            </AnimationFade>
            <Wrapper>
              <CardBody>
                <CardDate>{date}</CardDate>
                <CardTitle>{title}</CardTitle>
                <CardDescription>
                  <ReactMarkdown>{description}</ReactMarkdown>
                </CardDescription>

                <Pagination page={pageContext} />
              </CardBody>

              <BlogRelated slug={slug} />
            </Wrapper>
          </Card>
        </Container>
      </Layout>
    </Theme>
  )
}

export default Post
