import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding-bottom: 5rem;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
`

const Item = styled.li`
  padding: 0;

  & > a {
    width: fit-content;
    text-transform: uppercase;
    padding: 1rem 1.5rem 1.1rem;
    border-radius: 0.25rem;
    border: none;
    font-size: clamp(1.4rem, 2vw, 1.6rem);
    font-weight: normal;
    line-height: 1;
    background: ${({ theme: { colors } }) => colors.darkgreen};
    color: ${({ theme: { colors } }) => colors.white};
    cursor: pointer;
  }
`

const Pagination = ({ page }: any) => {
  const { prevPost, nextPost } = page

  return (
    <Container>
      <List>
        <Item>
          {prevPost && (
            <Link
              to={`/post/${prevPost.frontmatter.slug}`}
              rel='prev'
            >{`<< ${prevPost.frontmatter.slug}`}</Link>
          )}
        </Item>
        <Item>
          {nextPost && (
            <Link
              to={`/post/${nextPost.frontmatter.slug}`}
              rel='next'
            >{`${nextPost.frontmatter.slug} >>`}</Link>
          )}
        </Item>
      </List>
    </Container>
  )
}

export default Pagination
