import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { maxScreen, deviceScreen } from 'styles/_breakpoints'

const Container = styled.section`
  width: 100%;
  height: fit-content;
  margin: 2rem 0 10rem;
`

const Title = styled.h2`
  margin-bottom: 5rem;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-auto-rows: auto;
  gap: 3rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 1fr;
  }
`

const Card = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
`

const CardImageLink = styled(Link)`
  width: 100%;
  height: 100%;
`

const CardImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 70rem;
  border-radius: 1rem;

  @media ${maxScreen.smland} {
    height: 60rem;
  }

  @media ${deviceScreen.tablet} {
    height: 65rem;
  }

  @media ${deviceScreen.desktop} {
    height: 55rem;
  }

  @media ${deviceScreen.land} {
    height: 65rem;
  }
`

const CardBody = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  border-radius: 1rem;
  background: ${({ theme: { colors } }) => colors.opgreen};

  @media ${maxScreen.smland} {
    padding: 2rem;
  }

  @media ${deviceScreen.tablet} {
    padding: 2rem;
  }

  @media ${deviceScreen.desktop} {
    padding: 2rem;
  }
`

const CardTitle = styled.p`
  font-size: 2.5rem;
  font-family: ${({ theme: { fonts } }) => fonts.montserrat};
  font-weight: bold;
  margin-bottom: 1rem;
`

const CardDate = styled.p`
  margin-bottom: 0.25rem;
`

const CardDescription = styled.p`
  flex-grow: 1;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  line-height: 2rem;
`

const CardLink = styled(Link)`
  text-align: right;
  font-size: 1.7rem;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.black};
`

interface Datas {
  node: {
    frontmatter: Blog
  }
}

interface Datas {
  node: {
    frontmatter: Blog
  }
}

interface Blog {
  id: number
  slug: string
  title: string
  date: string
  snippet: string
  description: string
  images: {
    src: any
    alt: string
  }
}

const NewsRelated = ({ slug }: any) => {
  const datas = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/news/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              id
              slug
              title
              snippet
              date
              description
              images {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `)

  const filterDatas = datas.allMarkdownRemark.edges.filter(
    ({ node }: Datas) => node.frontmatter.slug !== slug,
  )

  const blogRelatedDatas = filterDatas.map(({ node }: Datas) => (
    <Card key={node.frontmatter.id}>
      <CardImageLink to={`/post/${node.frontmatter.slug}`}>
        <CardImage
          image={node.frontmatter.images.src.childImageSharp.gatsbyImageData}
          alt={node.frontmatter.images.alt}
        />
      </CardImageLink>
      <CardBody>
        <CardDate>{node.frontmatter.date}</CardDate>
        <CardTitle>{node.frontmatter.title}</CardTitle>
        <CardDescription>{node.frontmatter.snippet}...</CardDescription>
        <CardLink to={`/post/${node.frontmatter.slug}`}>{`Read Post`}</CardLink>
      </CardBody>
    </Card>
  ))

  return (
    <Container>
      <Title>Related</Title>
      <GridBox>{blogRelatedDatas}</GridBox>
    </Container>
  )
}

export default NewsRelated
